import React,{useState, useEffect} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col,Card, Button, Modal, Nav } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring'
import "./styles/catalogo.css"
import "../css/index.css"
// conection db firebase
import {store} from "../../components/Firebase/firebase-config"

// import frmUser

const Catalogo = ({id}) => {

  const [title, setTitle] = useState('');
  const [headerImg, setheaderImg] = useState('');
  const [productsList, setProductsList] = useState('');
  const [categoriesList, setcategoriesList] = useState('')

  useEffect(()=>{
    readCategories();
    readProducts();
  },[])

  const readProducts = async () => {
    const products =  store.collection('produtCatalog');
      const query =  products.where("user", "==", `${id}`, true).get()
      .then(async (querySnapshot) => {
          if(querySnapshot.size ==0){
             
          }else{
              const productArray = [];
              querySnapshot.forEach(async(doc) => {
                setheaderImg(`${doc.data()?.headerImg == null ? '' : doc.data()?.headerImg}`);
                setTitle(`${doc.data()?.title == null ? '' : doc.data()?.title}`);
                doc.data()?.products.map((product)=>{
                  productArray.push(product);
                });
              });
              setProductsList(productArray);
          }
      })
      .catch((err)=>{
          console.log(err)
      });
  };
  const readCategories = async () => {
    const categories =  store.collection('categoriesCatalog');
    const query =  categories.where("user", "==", `${id}`, true).get()
    .then(async (querySnapshot) => {
        if(querySnapshot.size ==0){
           
        }else{
          const categoriesArray = [];
          querySnapshot.forEach(async(doc) => {
            doc.data()?.categories.map((product)=>{
              categoriesArray.push(product);
            });
          });
          setcategoriesList(categoriesArray);
        }
    })
    .catch((err)=>{
        console.log(err)
    });
  };

  const readDetails = (name, description, img, typeView, category, link)=>{
    setName(name);
    setDescription(description);
    setImg(img);
    setTypeView(typeView);
    setCategory(category);
    setLink(link);
  };
  //#region Modal
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [img, setImg] = useState('');
  const [link, setLink] = useState('');
  const [typeView, setTypeView] = useState('');
  const [category, setCategory] = useState('');


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //#endregion

  const list = [
    {
      id:'ARG001',
      name:'Pagina web',
      description:'Creación de páginas web, en las que puedes elegir entre nuestras plantillas con un diseño estándar y personalizables, o puedes impactar con una página web diseñada a tu medida con tus requerimientos llevaremos tu negocio o servicios hasta las estrellas.',
      img:'https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Fservicio-img-03.jpg?alt=media&token=5a812a57-4c8c-4dd5-8736-dbc25d248c3d',
      category: 1,
      view: 1,
      link: 'https://wa.link/u4i058',
    },
    {
      id:'ARG002',
      name:'Landing Page',
      description:'Páginas de aterrizaje enfocadas en venta con llamado a la acción incentivando a los clientes potenciales con un reloj en cuenta regresiva, creación de base de clientes con correo, nombre y teléfono.',
      img:'https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Fservicio-img-02.jpg?alt=media&token=2ef987e1-87b6-458b-8920-605fc3c7df66',
      category: 1,
      view: 1,
      link: 'https://wa.link/mheyy7',
    },
    {
      id:'ARG003',
      name:'Tienda en linea',
      description:'Creación de tiendas en línea que permitirá la  personalización según tu empresa, con el maquetado personalizado podrás llevar el stock de tus diferentes productos y servicios de una forma más organizada, también podrás realizar ofertas y dar seguimiento a los clientes con su carrito de pedidos, tu tienda en línea puede contar con distintas funciones desde ser solamente un canal de venta, hasta un plataforma completa que te brinde reporte ventas costos y devoluciones.',
      img:'https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Fwebsite-servicio-img-01.jpg?alt=media&token=b21182af-0440-4746-878b-94456bb03178',
      category: 1,
      view: 1,
      link: 'https://wa.link/j5ujj9',
    },
    {
      id:'ARG004',
      name:'Tarjetas de presentación',
      description:'Brindar nuestros datos a un potencial cliente para que nos contacte cuando lo necesite es esencial y qué mejor que creando un vínculo y brindándole nuestro contacto para que lo tenga lo más cerca posible, realizamos tu tarjeta de presentación captando la esencia de tu empresa para que sea impactante y recordable. Nada mejor que una tarjeta de presentación que te ayude a cerrar un cliente potencial.',
      img:'https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Fimg-servicio-pagina-web.jpg?alt=media&token=23203916-374f-42c7-af99-794f3c3f8e4d',
      category: 2,
      view: 1,
      link: 'https://wa.link/kkvqty',
    },
    {
      id:'ARG005',
      name:'Diseño de logo',
      description:'La creación de logos aplicables a distintos formatos con diferentes vistas para que lo puedas usar de la forma que más te convenga, mostrandote distintas puebas de color para brindarte una mejor perspectiva de lo que podras hacer con tu logo.',
      img:'https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Fservicio-img-04.jpg?alt=media&token=2ce0f97c-dd52-46df-979d-e792ca6d7cab',
      category: 2,
      view: 1,
      link: 'https://wa.link/bbxso4',
    },
    {
      id:'ARG006',
      name:'Diseño de logo para APP',
      description:'Creación de logo aplicables de APP realizado segun el estilo y concepto de tu empresa con la alternativa de tener una animación de carga y una de inicio.',
      img:'https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Fservicio-img-05.jpg?alt=media&token=d99e4a59-61f5-4b64-b3e3-400790658491',
      category: 2,
      view: 1,
      link: 'https://wa.link/t3kdma',
    },
    {
      id:'ARG007',
      name:'Catálogo de productos',
      description:'Catálogo de productos o servicios conectado con WhatsApp con carrito de compras para los clientes y finalización con mensaje de WhatsApp al número de teléfono configurado, detallando el pedido completo del comprador.',
      img:'https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Fheaders-catalogo.jpg?alt=media&token=37ed2c8d-03f3-47d5-b88d-db035eeae50c',
      category:3,
      view: 1,
      details: {
        month1:{
          time: "1 Mes",
          description: "Tarjetas de invitación digitales visibles por 1 més",
          price: "12.45",
          units:"5"
        }
      },
    },
    {
      id:'ARG008',
      name:'Tarjetas de presentación digitales',
      description:'Tarjeta de presentación digital con Imagen, Nombre, Cargo, Descripción, Logo, Redes sociales, opción de Guardas y compartir el contacto, incluye la creación de un QR para el acceso a la tarjeta.',
      img:'https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Ftarjetas-servicio-img.jpg?alt=media&token=48dc113e-5747-4dc7-92ab-f0d3b13a082d',
      category:3,
      view: 1,
      details:[
        {
          title: '1 Més',
          description: 'Descripción especifica por el més correspondiente',
          price:'12.99',
          count: '45'
        },
        {
          title: '1 Més',
          description: 'Descripción especifica por el més correspondiente',
          price:'12.99',
          count: '45'
        },
      ],
      details: {
        month1:{
          time: "1 Mes",
          description: "Tarjetas de invitación digitales visibles por 1 més",
          price: "12.45",
          units:"5"
        }
      },
    },
    // {
    //   id:'ARG009',
    //   name:'Mini landing page',
    //   description:`Mini Landing Page Mini Landing Page Mini Landing Page Mini Landing Page Mini Landing Page Mini Landing Page Mini Landing Page
    //   Mi Mini Landing Page`,
    //   img:'https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Fimg-servicio-pagina-web.jpg?alt=media&token=23203916-374f-42c7-af99-794f3c3f8e4d',
    //   category:3,
    //   view: 1,
    //   details:[
    //     {
    //       title: '1 Més',
    //       description: 'Descripción especifica por el més correspondiente',
    //       price:'12.99',
    //       count: '45'
    //     },
    //     {
    //       title: '1 Més',
    //       description: 'Descripción especifica por el més correspondiente',
    //       price:'12.99',
    //       count: '45'
    //     },
    //   ],
    //   details: {
    //     month1:{
    //       time: "1 Mes",
    //       description: "Tarjetas de invitación digitales visibles por 1 més",
    //       price: "12.45",
    //       units:"5"
    //     }
    //   },
    // },
    // {
    //   id:'ARG010',
    //   name:'Tarjetas de invitación digitales',
    //   description:'Elaboración de catalogo de productos muy similar a este',
    //   img:'https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Fimg-servicio-pagina-web.jpg?alt=media&token=23203916-374f-42c7-af99-794f3c3f8e4d',
    //   category:3,
    //   view: 1,
    //   details:[
    //     {
    //       title: '1 Més',
    //       description: 'Descripción especifica por el més correspondiente',
    //       price:'12.99',
    //       count: '45'
    //     },
    //     {
    //       title: '1 Més',
    //       description: 'Descripción especifica por el més correspondiente',
    //       price:'12.99',
    //       count: '45'
    //     },
    //   ],
    //   month3: 'details',
    //   month6: 'details',
    //   month12: 'details',
    //   detail: [
    //     {
    //       title: "1 Més",
    //       price: "12.45",
    //       description: "Es una descripción muy interesante Es una descripción muy interesante Es una descripción muy interesante",
    //       units: "45"
    //     },
    //     {
    //       title: "3 meses",
    //       price: "80.45",
    //       description: "Es una descripción muy interesante Es una descripción muy interesante Es una descripción muy interesante",
    //       units: "45"
    //     }
    //   ],
    // },

  ];


  function SeeMore(props){
    const description = props.description;
    const name = props.name;
    const img = props.img;
    const typeView = props.typeView;
    const category = props.category;
    const link = props.link;
    if(description.length > 120){
      return(
        <div>
          <span>{description.slice(0,120)}</span>
          <a href = "" onClick={(e)=>{
              e.preventDefault();
              readDetails(name, description, img, typeView, category, link);
              handleShow();
            }}
          >... Leer Más</a>
        </div>
        // <button class="btn btn-success">Ver más</button>
      )
    }else{
      return(
        description
      )
    }
  }

  function Listas(prop){
    const category = prop.category; 
    if(productsList?.length > 0){
      const HeaderCategory = (prop) => {
        const index = prop.index;
        if(index == 0){
          return (
            <Col sm={12} md={12} lg={12}>
              <h2>{category}</h2>
            </Col>
          )
        }
        return <></>;
      }
      const elemento = productsList.map((product,index) => {
        if(category == product.category && product.view == 1){
          return(
            <>
            <HeaderCategory index={index}/>
            <Col sm={12} md={4} lg={4} className="mt-1 mb-1">
              <Card className="shadow" style={{borderRadius: "20px"}}>
                <Card.Body>
                  <div className="row">
                    <div className="col-12">
                      <h3>{product.name}</h3>
                    </div>
                    <div className="col-12">
                      <img 
                      src={product.img} 
                      alt="img-servicio-pagina-web"
                      style={{
                        borderRadius: "10px",
                        height: "200px",
                        width: "100%",
                      }}
                      />
                    </div>
                    <div className="col-12">
                      <span className="text-left">
                        <SeeMore 
                          description={product.description}
                          name={product.name}
                          img={product.img}
                          category = {product.category}
                          typeView = {product.typeView}
                          link = {product.link}
                        ></SeeMore>
                      </span>
                    </div>
                  </div>   
                  <div className="row mt-3">
                    <div className="col-12 m-1">
                        <a onClick={(e)=>{
                          e.preventDefault();
                          readDetails(
                            product.name, 
                            product.description, 
                            product.img, 
                            product.typeView, 
                            product.category, 
                            product.link);
                          handleShow();
                        }}className="btn btn-sm btn-block btn-blue mt-1">Leer Más</a>
                    </div>
                    <div className="col-12 m-1">
                      <a href={product.link} className="btn btn-block float-left btn-gray">Cotizar</a>
                    </div>
                  </div>             
                </Card.Body>
              </Card>
            </Col>
            </>
          )
        }
        if(category == product.category){
          function Interno(){
            return(
              <>
              <HeaderCategory index={index}/>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mb-3">
                <Card className="shadow" style={{borderRadius: "20px"}}>
                    <Card.Body>
                        <div className="row">
                            <div className="col-12">
                              <h3>{product.name}</h3>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                              <div className="row">
                                <div className="col-12 mb-2">
                                    <button className="btn btn-sm btn-outline-primary mr-1" style={{fontSize:"13px"}}>1 més</button>
                                    <button className="btn btn-sm btn-outline-primary mr-1" style={{fontSize:"13px"}}>3 meses</button>
                                    <button className="btn btn-sm btn-outline-primary mr-1" style={{fontSize:"13px"}}>6 meses</button>
                                    <button className="btn btn-sm btn-outline-primary mr-1" style={{fontSize:"13px"}}>1 año</button>
                                  </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                              <div className="row">
                                <div className="col-12">
                                  <img 
                                  src={product.img} 
                                  alt="img-servicio-pagina-web"
                                  style={{
                                  borderRadius: "10px",
                                  height: "180px"
                                  }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                <div className="row">
                                  <div className="col-12">
                                      <span className="text-left">
                                        <SeeMore 
                                          description={product.description}
                                          name={product.name}
                                          img={product.img}
                                          category = {product.category}
                                          typeView = {product.typeView}
                                          link = {product.link}
                                        ></SeeMore>
                                      </span>
                                  </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <a href={product.link} className="btn btn-sm btn-block btn-gray mt-1">Leer Más</a>
                            </div>
                            <div className="col-12">
                                <a href={product.link} className="btn btn-sm btn-block btn-gray mt-1">Cotizar</a>
                            </div>
                        </div>                
                    </Card.Body>
                </Card>
              </div>
              </>
            )
          }
          return(
            <Interno></Interno>
          )
        }
      });
      return elemento;
    }else{
      return <></>;
    }
  }

  function CategoryItem(){
    if(categoriesList?.length > 0){
      return(
        categoriesList.map((category)=>
          <Container className="mt-5 mb-3">
          <Row>
            <Listas category={category.name}></Listas>
          </Row>
          </Container>
        )
      )
    }else{
      return(<></>)
    }
  }
  
  function Header(prop){
    const props = useSpring({
      to: {opacity: 1},
      from: { opacity:0 },
      config: { duration: 1000 }
    })
    return <animated.div style={props}><h1 className="text-center mt-5 mb-5 p-5 text-header">{title}</h1></animated.div>
  };

  const ViewDefault = () => {
    const props = useSpring({
      to: {opacity: 1},
      from: { opacity:0 },
      config: { duration: 1000 }
    })

    return(
      <Container fluid 
        style={{
            background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.9)), url("https://firebasestorage.googleapis.com/v0/b/argos-website.appspot.com/o/index%2Fportada-catalogo.jpg?alt=media&token=01d27e5d-ed22-413e-bdbf-6cdf183a902b")`,
            backgroundSize:"100% ",
            backgroundColor: "black",
            backgroundRepeat: 'no-repeat',
          }
        }
        className="bg-header"
      >
        <Row style={{
          position: "relative",
          color: "#ffffff", 
          textAlign: "center",
        }}>
          <Col sm={12} md={12} lg={12} className="mt-5 mb-5">
            <animated.div 
              style={props}>
                <h1 className="text-center mt-5  pt-5 text-header">
                  Este catalogo no existe
                </h1>
                <p className="text-center mb-5">Pero puedes formar parte de nuestra familia con solo un clic</p>
                <Button variant="secondary" className="btn-try-ligth">Comienza a vender en 5 minutos</Button>{' '}

            </animated.div>
          </Col>
        </Row>
      </Container>
    )
  }


  const ViewCatalog = () => {
    if(title != ''){
      return(
        <>
          <Container fluid 
            style={
              headerImg != '' ? {
                background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.9)), url('${headerImg}')`,
                backgroundSize:"100% ",
                backgroundColor: "black",
                backgroundRepeat: 'no-repeat',
              } : {}
            }
            className="bg-header"
          >
            <Row style={{
              position: "relative",
              color: "#ffffff", 
              textAlign: "center",
            }}>
              <Col sm={12} md={12} lg={12} className="mt-5 mb-5">
                
                <Header />
              </Col>
            </Row>
          </Container>
          {/* <Container>
            <Row>
              <Col sm={12} md={12} lg={12} className="mb-2 mt-5">
                <p style={{fontSize:"21px"}} className="text-center">Selecciona una categoria</p>
                <select name="" id="" className="form-control">
                  <option>Puedes seleccionar una categoria</option>
                  <option value="1">Desarrollo web</option>
                  <option value="2">Servicios de diseño</option>
                  <option value="3">Servicios y productos digitales</option>
                </select>
              </Col>
            </Row>
          </Container> */}
          <CategoryItem></CategoryItem>
          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row className="justify-content-md-center">
                  <Col sm={12} md={12} lg={10}>
                    <img 
                      src={img}
                      style={{
                        borderRadius: "20px"
                      }} 
                      alt="img-description" 
                    />
                  </Col>
                  <Col sm={12} md={12} lg={10}>
                    <p className="text-left">{description}</p>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row className="justify-content-md-center">
                  <Col sm={12} md={10} lg={10}>
                    <div className="d-grid gap-2">
                      <a href={link} variant="secondary" className=" btn btn-block btn-gray mt-1 mb-3" size="">
                        Cotizar
                      </a>
                    </div> 
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </>
      )
    }
    return <ViewDefault/>
  }

  return(
  <>
    <SEO title={`${title}`} />
    <Layout>
      <ViewCatalog />
    </Layout>
  </>
  )
  
}

export default Catalogo
